// Config values
$platform-name: 'hestia';

// Colours
$brand-primary: #2B4570;
$brand-secondary: #F1BC41;

// Misc
$body-colour: #141414;
$headings-colour: $brand-primary;

// Create greyscale
$black: #000;
$grey-dark: #292b2c;
$grey: #464a4c;
$grey-light: #636c72;
$grey-lighter: #eceeef;
$grey-lightest: #f7fafd;
$white: #fff;

// Typography
$font-family-base: "Open Sans", sans-serif;
$headings-font-family: "Open Sans", sans-serif;

// Layout
$container-max-width: 1280px;

// Main call to action
$cta-btn-gap: 15px;

// Top level items
$nav-top-level-item-padding: 16px 20px;
$nav-top-level-item-hover-background-colour: $grey-lighter;

// Submenus
$nav-submenu-background-colour: $grey-lighter;

// Header content
$header-content-margin-top: 20px;
$header-content-margin-bottom: 20px;
$header-content-padding-top: 0;
$header-content-padding-bottom: 0;

// Search
$header-search-input-border-colour: transparent;
$header-search-input-border-width: 0; // t-r-b-l

// Search - button
$header-search-button-background-colour: transparent;
$header-search-button-icon-colour: $brand-primary;

// Carousel
$carousel-max-width: 100%;
$carousel-heading-font-size: 49px;
$carousel-heading-colour: #fff;
$carousel-contents-max-width: $container-max-width;
$carousel-details-padding: 0;
$carousel-details-position-y: center; // top, center, bottom
$carousel-details-position-x: left; // left, center, right
$carousel-details-max-width: 45%;
$carousel-details-background-colour: transparent;
$carousel-summary-enabled: false;
$carousel-read-more-background-colour: $brand-secondary;
$carousel-read-more-border-colour: $brand-secondary;
$carousel-read-more-colour: $brand-primary;
$carousel-read-more-font-size: 16px;

// Carousel controls (left-right buttons)
$carousel-controls-enabled: false;

// Carousel buttons (dots or tabs)
$carousel-buttons-enabled: false;

// Home intro
$home-intro-background-colour: transparent;
$home-intro-padding-y: 0;
$home-intro-margin-top: 0;
$home-intro-margin-bottom: 50px;
$home-intro-colour: $brand-primary;
$home-intro-mobile-padding-y: 0;

// Home features
$home-features-padding-bottom: 0;

// Quick giving panel
$quick-giving-background-colour: transparent;
$quick-giving-padding: 0;
$quick-giving-border-radius: 0;
$quick-giving-donation-amount-figure-font-size: 20px;
$quick-giving-donation-amount-padding: 11px 13px;
$quick-giving-donation-amount-background-colour: transparent;
$quick-giving-donation-amount-figure-colour: #141414;
$quick-giving-donation-amount-border-width: 1px;
$quick-giving-donation-amount-border-colour: $brand-primary;
$quick-giving-donation-amount-selected-background-colour: $brand-primary;
$quick-giving-donation-amount-selected-figure-colour: #fff;

// Home quick giving panel
$home-quick-giving-padding-y: 0;
$home-quick-giving-background-colour: transparent;
$home-quick-giving-donation-amount-background-colour: transparent;
$home-quick-giving-donation-amount-figure-colour: #141414;
$home-quick-giving-donation-amount-border-width: 1px; // Only comes into play with transparent background
$home-quick-giving-donation-amount-border-colour: $brand-primary;
$home-quick-giving-donation-amount-selected-background-colour: $brand-primary;
$home-quick-giving-donation-amount-selected-figure-colour: #fff;
$home-quick-giving-donate-btn-background-colour: $brand-primary;
$home-quick-giving-donate-btn-colour: #fff;
$home-quick-giving-donate-btn-border-colour: $brand-primary;
$home-quick-giving-donate-btn-font-size: 16px;

// Card
$card-border-radius: 8px;
$card-details-background-colour: rgba(43, 69, 112, 0.05);
$card-summary-colour: #141414;
$card-hover-summary-colour: #141414;
$card-gap-width: 40px;

// Home feeds
$home-feeds-max-width: 100%;
$home-feeds-padding-top: 0;
$home-feeds-padding-bottom: 0;

// Posts
$post-content-max-width: 1088px;

// Menu admin
$menu-admin-enabled: false;

// Footer admin links
$footer-admin-basket-link-enabled: true;
$footer-admin-login-link-enabled: true;
$footer-admin-manager-link-enabled: true;
$footer-admin-my-details-link-enabled: true;
$footer-admin-logout-link-enabled: true;

// FAQs
$faq-content-max-width: 1280px;

// Sidebar
$sidebar-enabled: false; 
$sidebar-layout: below;

// Associated lists
$associated-related-enabled: false;
$associated-most-read-enabled: false;
$associated-max-number-of-posts: 3;

// Social Icons
$social-icons-colour: $brand-primary; // A colour, or 'brand'

// Slick
$slick-enabled: true;